import AboutUs from '../components/AboutUs';
import Intro from '../components/Intro';
import ContactUs from '../components/Contact';
import OffstedRegistered from '../components/OffstedRegistered';
import Qualifications from '../components/Qualifications';
import SettingGallery from '../components/SettingGallery';

const Home = () => {
    return (
        <>
        <Intro />
        <AboutUs />
        <Qualifications />
        <OffstedRegistered />
        <SettingGallery />
        <ContactUs />
      </>
    )
}

export default Home;
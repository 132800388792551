

const PrivacyPolicy = () => {
    return (
        <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto flex flex-col">
                <div className="lg:w-4/6 mx-auto">
                    <div className="flex flex-col sm:flex-row mt-10">
                        <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                            <div className="flex flex-col items-center text-center justify-center">
                                <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">Privacy Policy</h2>
                                <div className="w-12 h-1 bg-pink-500 rounded mt-2 mb-4"></div>
                                <p className="text-base">Your privacy matters to us.</p>
                            </div>
                        </div>
                        <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                            <h1 className="text-2xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
                            <p className="leading-relaxed text-lg mb-4">
                                <strong>Effective Date:</strong> 6th December 2024
                            </p>
                            <p className="leading-relaxed text-lg mb-4">
                                The One Childcare ("we," "our," "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit our website, interact with our contact form, and utilize our services.
                            </p>
                            <p className="leading-relaxed text-lg mb-4">
                                <strong>1. Information We Collect</strong>
                                <br />
                                We collect only the following types of information:
                            </p>
                            <ul className="list-disc ml-5 mb-4">
                                <li>
                                    <strong>Contact Form Submissions:</strong> When you voluntarily fill out our contact form, we collect the information you provide, including your name, email address, phone number (if applicable), and any additional information you include in your message.
                                </li>
                                <li>
                                    <strong>Automatically Collected Data via Google Services:</strong>
                                    <ul className="list-disc ml-5">
                                        <li>
                                            <strong>Google Analytics:</strong> To analyze website usage and improve functionality.
                                        </li>
                                        <li>
                                            <strong>Google AdWords:</strong> For targeted advertising.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p className="leading-relaxed text-lg mb-4">
                                <strong>2. How We Use Your Information</strong>
                                <br />
                                The information we collect is used solely to respond to your inquiries, analyze website trends, and optimize our advertising campaigns.
                            </p>
                            <p className="leading-relaxed text-lg mb-4">
                                <strong>3. Data Sharing and Disclosure</strong>
                                <br />
                                We share data only with trusted service providers (e.g., Google Analytics and Google AdWords) or as required by law.
                            </p>
                            <p className="leading-relaxed text-lg mb-4">
                                <strong>4. Cookies and Tracking Technologies</strong>
                                <br />
                                We use cookies for analytics and advertising purposes. You can manage preferences via Google Ads Settings and the Google Analytics Opt-Out Browser Add-on.
                            </p>
                            <p className="leading-relaxed text-lg mb-4">
                                <strong>5. Data Security</strong>
                                <br />
                                We implement secure measures to protect your information.
                            </p>
                            <p className="leading-relaxed text-lg mb-4">
                                <strong>6. Your Rights and Choices</strong>
                                <br />
                                You may access, correct, or delete your submitted information by contacting us.
                            </p>
                            <p className="leading-relaxed text-lg mb-4">
                                <strong>7. Changes to This Privacy Policy</strong>
                                <br />
                                We reserve the right to update this Privacy Policy. Changes will be posted with an updated effective date.
                            </p>
                            <p className="leading-relaxed text-lg mb-4">
                                <strong>Contact Us</strong>
                                <br />
                                If you have any questions, please reach out to us at <strong>via the contact <a href="/#contact-us">form</a></strong>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicy;

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Footer from './components/Footer';
import Header from './components/Header';
import ReactGA from 'react-ga4';
import Home from './pages/Home';
import PrivacyPolicy from './components/PrivacyPolicy';


const TRACKING_ID = "G-456827597";

function App() {
  ReactGA.initialize(TRACKING_ID);
  return (
    <>
    <BrowserRouter >
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      <Footer />
    </BrowserRouter>  
    </>
  );
}

export default App;
